import { Component, Host, Prop, h } from '@stencil/core';

@Component({
  tag: `ptz-progress-bar`,
  styleUrl: 'ptz-progress-bar.scss',
  shadow: false,
})
export class PTZProgressBar {
  @Prop() label: string;
  @Prop() progress: number;
  @Prop() color: string = '#454545';
  @Prop() percentDisable: boolean = false;

  render() {
    return (
      <Host>
        <div>
          <span class="ptz-progress-bar-title">{this.label}</span>
          <div class="progress-bar-container">
            <div class="progress-bar-content">
              <div class="progress-bar" style={{ width: `${this.progress}%`, backgroundColor: this.color }}></div>
            </div>
            {!this.percentDisable && <span>{this.progress}%</span>}
          </div>
        </div>
      </Host>
    );
  }
}
