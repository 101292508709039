ptz-progress-bar {
  .ptz-progress-bar-title {
    color: $petz-color-neutral-darker-accent;
    font-size: $petz-font-size-3xs;
    font-weight: $petz-font-weight-bold;
    line-height: $petz-font-size-xs;
  }

  .progress-bar-container {
    align-items: center;
    display: flex;
    gap: $petz-spacing-2xs;

    .progress-bar-content {
      background-color: $petz-color-neutral-lighter-accent;
      border: 1px solid $petz-color-neutral-lighter-accent;
      border-radius: $petz-spacing-6xl;
      height: $petz-spacing-2xs;
      overflow: hidden;
      width: 100%;
    }

    .progress-bar {
      align-items: center;
      border-radius: $petz-spacing-6xl;
      display: flex;
      height: 100%;
      transition: width 0.3s ease-in-out;
    }

    .progress-bar span {
      color: $petz-color-neutral-darker-accent;
      font-size: $petz-font-size-xs;
      margin: 0 auto;
    }
  }
}
